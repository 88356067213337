import { Box, Typography, Select, MenuItem, Switch, FormControl, InputLabel } from '@mui/material';
import { DrawerMenu } from '../../../components/DrawerMenu';
import { useEffect, useRef, useState } from 'react';
import { ModalPedido } from '../../../components/ModalPedido';
import socketIOClient from 'socket.io-client';
import { ValidaLogin } from '../../../config/ValidaLogin';
import { ApiAdmin } from '../../../config/ApiAdmin';

export const HomeAdmin = () => {
  const [pedidos, setPedidos] = useState([]);
  const [filtroStatus, setFiltroStatus] = useState('Em andamento');
  const [pedidoSelecionado, setPedidoSelecionado] = useState();
  const [openModalPedido, setOpenModalPedido] = useState(false);
  const [notificationEnabled, setNotificationEnabled] = useState(false);

  useEffect(() => {
    buscarPedidos();
    const socket = socketIOClient('https://api.lanchedobaba.gdelivery.app.br');
   // Substitua 'seu-backend-url' pela URL do seu backend
    socket.on('pedidosAtualizados', () => {
      buscarPedidos(); // Quando houver atualizações, atualize a lista de pedidos
      if (notificationEnabled) {
        playNotificationSound();
      }
    });
    return () => {
      socket.disconnect(); // Desconecte o socket quando o componente for desmontado
    };
  }, [notificationEnabled]);
  
  const handleNotificationToggle = () => {
    setNotificationEnabled(!notificationEnabled); // Inverte o estado do som de notificação
  };

  const playNotificationSound = () => {
    const audio = new Audio('/notification.mp3');
    audio.muted = false; // Garante que o som não esteja silenciado
    audio.play();
  };
  

  const buscarPedido = (id) => {

    ApiAdmin.get('pedido/' + id).then(function (response) {
      setPedidoSelecionado(response.data.data)
      console.log(response.data)
      setOpenModalPedido(true);
    }).catch(function (error) {
      console.log(error)
    })
  }

  const buscarPedidos = () => {
    ApiAdmin.get('/pedidos')
      .then(function (response) {
        setPedidos(response.data.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleStatusChange = (event, pedidoId) => {
    const newStatus = event.target.value;
    // Atualizar o status no backend usando a API (pode variar dependendo do seu backend)
    ApiAdmin.put(`/pedidos/${pedidoId}`, { status: newStatus,horaconclusao: new Date()})
      .then((response) => {
        // Sucesso ao atualizar o status, atualize o estado local
        const updatedPedidos = pedidos.map((pedido) =>
          pedido.id === pedidoId ? { ...pedido, status: newStatus,horaconclusao: new Date() } : pedido
        );
        setPedidos(updatedPedidos);
      })
      .catch((error) => {
        console.error('Erro ao atualizar status:', error);
      });
  };

  const filtrarPedidos = () => {
    if (filtroStatus === 'Em andamento') {
      return pedidos.filter((pedido) => pedido.status === 'Em andamento' || pedido.status === 'Saiu para entrega');
    } else if (filtroStatus === 'Todos') {
      return pedidos;
    } else {
      return pedidos.filter((pedido) => pedido.status === filtroStatus);
    }
  };
  

  const ordenarPedidos = (pedidos) => {
    return pedidos.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  };

  const handleFiltroStatusChange = (event) => {
    setFiltroStatus(event.target.value);
  };

  return (
    <ValidaLogin>


    <DrawerMenu>
      <Box>
    
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>


          <Box>
            <Typography>Delivery Hoje</Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
          <Switch checked={notificationEnabled} onChange={handleNotificationToggle} />
            <Select value={filtroStatus} onChange={handleFiltroStatusChange}>
              <MenuItem value="Todos">Todos</MenuItem>
              <MenuItem value="Concluído">Concluídos</MenuItem>
              <MenuItem value="Em andamento">Em Andamento</MenuItem>
            </Select>
          </Box>
        </Box>
        {ordenarPedidos(filtrarPedidos()).map((pedido, index) => (
          <Box
            key={index}
            component={'div'}
            onClick={() => buscarPedido(pedido.id)}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: 1,
              border: '1px solid #eee',
              cursor: 'pointer',
              transition: 'background-color 0.5s',
              ':hover': { backgroundColor: 'lightgrey' }

            }}
          >

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontSize: '14px' }}>#{pedido.codigopedido}</Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>{pedido.cliente.nome}</Typography>
              <Typography sx={{ fontSize: '12px', fontWeight: 'bolder' }}>
                feito às {new Date(pedido.createdAt).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' })}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', gap: 1 }}>
            <FormControl sx={{ minWidth: 120 }}>
                <Select
                  value={pedido.status}
                  onChange={(event) => handleStatusChange(event, pedido.id)}
                  onClick={(event) => event.stopPropagation()}
                >
                  <MenuItem value="Em andamento">Em andamento</MenuItem>
                  <MenuItem value="Saiu para entrega">Saiu para entrega</MenuItem>
                  <MenuItem value="Concluído">Concluído</MenuItem>
                </Select>
              </FormControl>
              <Typography sx={{ fontSize: '12px', fontWeight: 'bolder' }}>R$ {pedido.valorTotal.toFixed(2)}</Typography>
            </Box>
          </Box>
        ))}
        {pedidoSelecionado && (
          <ModalPedido pedido={pedidoSelecionado} open={openModalPedido} onClose={() => setOpenModalPedido(false)} />
        )}
      </Box>
    </DrawerMenu>
    </ValidaLogin>
  );
};
