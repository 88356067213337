import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { CartProvider } from './context/CartProvider';
import authReducer from './redux/authReducer';
import { Provider } from 'react-redux';
import { createStore } from 'redux';


const root = ReactDOM.createRoot(document.getElementById('root'));
const store = createStore(authReducer);

root.render(


  <Provider store={store}>
  <CartProvider>
    <App />
    </CartProvider>
        
  </Provider>

);


