import { Box, Modal } from "@mui/material"



export const ModalUpdate =({children,openModal,closeModal})=>{
    return(
      
        <Modal open={openModal} onClose={closeModal}>
            <Box sx={{width: '100vw',height:'100vh',display:'flex',justifyContent:'center',alignItems:'center'}}> 
            {children}
            </Box>
        </Modal>
       
    )
}