import React, { useEffect, useState, useRef } from "react";
import { DrawerMenu } from "../../../components/DrawerMenu";
import { Tab, Tabs, Box, Typography, CardMedia, Container, Button, Stack } from "@mui/material";
import Switch from '@mui/material/Switch';
import { ModalCadProduto } from "../../../components/ModalCadProduto";
import { ModalEditarProduto } from "../../../components/ModalEditarProduto";
import { ApiAdmin } from "../../../config/ApiAdmin";


export const Cardapio = () => {
  const [categorias, setCategorias] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const containerRef = useRef(null);
  const [openModalCadastro, setOpenModalCadastro] = useState(false)
  const [categoriaSelecionada, setCategoriaSelecionada] = useState(null)
  const [novoStatus, setNovoStatus] = useState("ativado")
  const [openModalEditar, setOpenModalEditar] = useState(false)
  const [produtoSelecionado, setProdutoSelecionado] = useState([])

  useEffect(() => {
    buscarCategorias();
  }, []);

  const buscarCategorias = () => {
    ApiAdmin.get("categorias")
      .then(function (response) {
        setCategorias(response.data.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    scrollToSection(newValue);
  };

  const scrollToSection = (index) => {
    const sectionId = categorias[index].id.toString();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleScroll = () => {
    const containerTop = containerRef.current.offsetTop;
    const scrollPosition = window.pageYOffset;

    let activeIndex = 0;
    let minDistance = Infinity;

    categorias.forEach((categoria, index) => {
      const section = document.getElementById(categoria.id);
      if (section) {
        const sectionTop = section.offsetTop - containerTop;
        const distance = Math.abs(sectionTop - scrollPosition);
        if (distance < minDistance) {
          minDistance = distance;
          activeIndex = index;
        }
      }
    });

    setActiveTab(activeIndex);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [categorias]);
  useEffect(() => {
    if (categorias.length > 0) {
      scrollToSection(0); // Rolando para a primeira categoria quando as categorias forem carregadas
    }
  }, [categorias]);

  const AbreModalCadastro = (categoriaId) => {
    setCategoriaSelecionada(categoriaId)
    setOpenModalCadastro(true)
  }
  const closeModal = () => {
    setOpenModalCadastro(false)
    buscarCategorias()
  }


  const handleClickCardProduto = (event, produto) => {
    // Verificar se o alvo do evento é o Switch ou um elemento filho do Switch
    if (event.target.tagName === "INPUT" || event.target.closest("input[type='checkbox']")) {
      return; // Retornar imediatamente se o alvo for o Switch ou um elemento filho do Switch
    }
  
    // Se não for o Switch, então podemos buscar o produto e abrir o modal
    BuscarProduto(produto.id);
  };

  const AlterarSwitch = (event, produtoId) => {
    const isChecked = event.target.checked
    const newStatus = isChecked ? "ativo" : "inativo"

    setNovoStatus(newStatus);
    ApiAdmin.put("produto/update/" + produtoId, {
      status: newStatus
    }).then(function (response) {
      console.log('status alterado com sucesso')
    }).catch(function (error) {
      console.log(error)
    })
  }

  const abrirModalEditar = (produto) => {
    setProdutoSelecionado(produto);
    setOpenModalEditar(true)
 
  };

  const BuscarProduto = (id) => {

    ApiAdmin.get('produto/' + id).then(function (response) {
  
      console.log(response.data)
      abrirModalEditar(response.data.data)
      
    }).catch(function (error) {
      console.log(error)
    })
  }

  return (
    <DrawerMenu>
      <Container ref={containerRef}>

        <Box sx={{ position: 'sticky', top: 0, left: 0, right: 0, zIndex: 1, bgcolor: 'white', textAlign: 'center' }}>
          <Typography>Lanche do Babá</Typography>
          <Typography>Prazer em servir sempre</Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', bgcolor: "white", width: "100%" }}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              {categorias.map((categoria, index) => (
                <Tab key={categoria.id} value={index} label={categoria.nome} />
              ))}
            </Tabs>
          </Box>
        </Box>

        {categorias.map((categoria, index) => (
          <Box key={categoria.id} id={categoria.id} sx={{ paddingTop: index === 0 ? '100px' : '10px' }}>


            <Box sx={{ border: "1px solid #eee", p: 2 }}>
              <Typography variant="h4">{categoria.nome}</Typography>
            </Box>
            {categoria.Produtos.map((produto) => (
              <Box
                key={produto.id}
                component={'div'}
                onClick={(event) => handleClickCardProduto(event, produto)}
                sx={{

                  display: "flex",
                  justifyContent: "space-between",
                  p: 1,
                  border: "1px solid #eee",
                  cursor: 'pointer',
                  transition: "background-color 0.5s",
                  ":hover": { backgroundColor: 'lightgrey' }
                }}

              >

                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Typography sx={{ fontSize: '18px' }}>{produto.titulo}</Typography>
                  <Typography sx={{ color: '#888' }}>{produto.descricao}</Typography>
                  <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>R$ {produto.valor.toFixed(2)}</Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Inativar</Typography>
                    <Switch defaultChecked onChange={(event) => AlterarSwitch(event, produto.id)} />
                    <Typography>Ativado</Typography>
                  </Stack>

                </Box>
                <CardMedia
                  sx={{ width: 100, height: 100, borderRadius: 10, marginX: 5 }}
                  image={"https://api.lanchedobaba.gdelivery.app.br/files/" + produto.img}
                />
              </Box>


            ))}
            {produtoSelecionado&& (
              <ModalEditarProduto
                openModal={openModalEditar}
                closeModal={() => setOpenModalEditar(false)}
                produto={produtoSelecionado}
              />
            )}
            <ModalCadProduto openModal={openModalCadastro} closeModal={closeModal} categoriaID={categoriaSelecionada}></ModalCadProduto>

            <Button variant="outlined" onClick={() => AbreModalCadastro(categoria.id)}>Novo Produto</Button>

          </Box>
        ))}
      </Container>
    </DrawerMenu>
  );
};
