import { Box, Button, Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { DrawerMenu } from "../../../components/DrawerMenu"
import { NotificaoSnack } from "../../../components/NotificacaoSnack"
import { useEffect, useState } from "react"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ModalUpdate } from "../../../components/ModalUpdate"
import { ApiAdmin } from "../../../config/ApiAdmin";


export const CadastroItemAdicional =()=>{
    const [openNotificacao,setOpenNotificacao]=useState()
    const [notificacao,setNotificacao]=useState()
    const [requisicaoOk,setRequisisaoOk]=useState()
    const [titulo,setTitulo]=useState()
    const [descricao,setDescricao]=useState()
    const [valor,setValor]=useState()
    const [itens,setItens]=useState([])
    const [itemSelecionado, setItemSelecionado] = useState(null);
    const [openModal,setOpenModal]=useState(false)

    useEffect(()=>{
       BuscarCategorias()
    },[setItens])

    const BuscarCategorias = ()=> {
        ApiAdmin.get('itens/')
        .then(function (response) {
          setItens(response.data.data)
          console.log(response)
        })
        .catch(function (error) {
        
          console.error(error);
        });
    }
    const CadastrarItem =(event)=>{
        event.preventDefault()
        if (!titulo || titulo.trim() === "") {
           alert("O campo de nome está vazio!");
            return;
          }
        ApiAdmin.post('itens/create', {
            titulo:titulo.trim(),
            descricao:descricao,
            valor:valor
          })
          .then(function (response) {
           
            console.log(response);
            setNotificacao(response.data.message)
            setOpenNotificacao(true)
            setRequisisaoOk(true)
            setTitulo("")
            setDescricao("")
            setValor("")
            BuscarCategorias();
          
          })
          .catch(function (error) {
            setNotificacao(error.response.data.message)
            setRequisisaoOk(false)
            setOpenNotificacao(true)
            console.error(error);
          });
        }

        const DeletarItem = (id) => {
            ApiAdmin.delete(`itens/delete/${id}`)
              .then(function (response) {
                console.log(response);
                setNotificacao(response.data.message)
                setRequisisaoOk(true)
                setOpenNotificacao(true)
                BuscarCategorias()
              })
              .catch(function (error) {
                setNotificacao(error.response.data.message)
                setOpenNotificacao(true)
                setRequisisaoOk(false)
                console.error(error);
              });
          }

          const PegarItem = (id) => {
           
            setItemSelecionado(id);
            setOpenModal(true);
          }
          

          const UpdateItem=(id)=>{
            ApiAdmin.put(`itens/update/${id}`,{
              titulo:titulo,
              descricao:descricao,
              valor:valor
            })
            .then(function (response) {
              console.log(response);
              setNotificacao(response.data.message)
              setRequisisaoOk(true)
              setOpenNotificacao(true)
              BuscarCategorias()
              setTitulo("")
              setDescricao("")
              setValor("")
              setOpenModal(false)
            })
            .catch(function (error) {
              setNotificacao(error.response.data.message)
              setOpenNotificacao(true)
              setRequisisaoOk(false)
              console.error(error);
            });
          }
          
        
        
    
    return (
        <DrawerMenu>
       <Container>
            <Typography sx={{ textAlign: 'center', fontWeight: 'bolder', fontSize: 20, p: 2 }}>Cadastro de Itens Adicionais</Typography>
            <Box component={'form'} onSubmit={CadastrarItem} sx={{ display: 'flex', flexDirection: 'column', gap: 5, }}>
                <TextField
                    label='Titulo'
                    required
                    onChange={(e)=>setTitulo(e.target.value)}
                    value={titulo || ""}
                />
                 <TextField
                    label='Descricao'
                   
                    onChange={(e)=>setDescricao(e.target.value)}
                    value={descricao || ""}
                />
                 <TextField
                    label='valor'
                    required
                    onChange={(e)=>setValor(e.target.value)}
                    value={valor || ""}
                />
                 <Button variant="contained" type="submit">Salvar</Button>
            </Box>
        
          <NotificaoSnack openNotificao={openNotificacao} requisicaoOk={requisicaoOk} closeNotificacao={()=>setOpenNotificacao(false)}>
            <Typography>{notificacao}</Typography>
          </NotificaoSnack>
            <Box sx={{ display: 'flex', justifyContent: 'center',mt:10 }}>
            
            <TableContainer component={Paper} >
                
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Ações</TableCell>
                                <TableCell>id</TableCell>
                                <TableCell>titulo</TableCell>
                                <TableCell>descricao</TableCell>
                                <TableCell>valor</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                        {itens.map((item,index)=>(
                        <TableRow key={index}>
                             <TableCell>
                                <IconButton onClick={()=>PegarItem(item)}><EditIcon/></IconButton>
                               <IconButton onClick={() => DeletarItem(item.id)}><DeleteIcon /></IconButton>
                                </TableCell>
                            
                             <TableCell>{item.id}</TableCell>
                            <TableCell>{item.titulo}</TableCell>
                            <TableCell>{item.descricao}</TableCell>
                            <TableCell>R$ {item.valor.toFixed(2)}</TableCell>
                            <ModalUpdate openModal={openModal} closeModal={()=>setOpenModal(false)}>
                              <Box sx={{bgcolor:'background.paper',width:400,height:400}}>
                                <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',gap:4}}>
                                <Typography sx={{fontSize:20,fontWeight:'bolder'}}>Digite o novo nome da categoria</Typography>
                                <TextField
                                 InputLabelProps={{
                                  shrink: true,
                              }}
                                label='titulo'
                                type="text"
                               defaultValue={itemSelecionado ? itemSelecionado.titulo : ""}
                               onChange={(e)=>setTitulo(e.target.value)}
                                />
                                 <TextField
                                 InputLabelProps={{
                                  shrink: true,
                              }}
                                label='descricao'
                                type="text"
                               defaultValue={itemSelecionado ? itemSelecionado.descricao : ""}
                               onChange={(e)=>setDescricao(e.target.value)}
                                />
                                 <TextField
                                 InputLabelProps={{
                                  shrink: true,
                              }}
                                label='valor'
                                type="text"
                               defaultValue={itemSelecionado ? itemSelecionado.valor.toFixed(2) : ""}
                               onChange={(e)=>setValor(e.target.value)}
                                />

                                <Box sx={{display:'flex',gap:3}}>
                                <Button variant="contained" onClick={()=>UpdateItem(itemSelecionado.id)}>Atualizar</Button>
                                <Button variant="contained" onClick={()=>setOpenModal(false)}>Cancelar</Button>

                                </Box>
                                </Box>
                                </Box>
                            </ModalUpdate>
                        
                        </TableRow>
              
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            </Container>
    </DrawerMenu>
    )
}