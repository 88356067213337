import {  useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"


export const ValidaLogin = ({children}) => {
    const [logado] = useState(localStorage.getItem('logado')) 
    const navigate = useNavigate()

    useEffect(()=>{
        if(!logado){
            alert('vc nao tem permissao para acessar essa rota')
            navigate('/login')
        }
    },[logado,navigate])

    return (
        <>
            {children}
        </>
    )
}