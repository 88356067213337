import { Autocomplete, Box, Button, Checkbox, Container, FormControlLabel, MenuItem, Modal, TextField, Typography } from "@mui/material"
import { NotificaoSnack } from "./NotificacaoSnack"
import { useEffect, useRef, useState } from "react"
import DeleteIcon from '@mui/icons-material/Delete';
import { DrawerMenu } from "./DrawerMenu"
import CloseIcon from '@mui/icons-material/Close';
import { ApiAdmin } from "../config/ApiAdmin"


export const ModalCadProduto = ({ openModal, closeModal, categoriaID }) => {

  const [categoria, setCategoria] = useState([])
  const [categoriaSelecionada, setCategoriaSelecionada] = useState()
  const [adicionais, setAdicionais] = useState([])
  const [itensAdicionado, setItensAdicionado] = useState([])
  const [titulo, setTitulo] = useState()
  const [descricao, setDescricao] = useState()
  const [valor, setValor] = useState()
  const [img, setImg] = useState()
  const [openNotificacao, setOpenNotificacao] = useState()
  const [notificacao, setNotificacao] = useState()
  const [opcoesPersonalizadas, setOpcoesPersonalizadas] = useState([]);
  const inputFileRef = useRef(null);
  const [autocompleteKey, setAutocompleteKey] = useState(0);

  useEffect(() => {
    BuscarItens()
    BuscarCategorias()
  }, [])

useEffect(()=>{
  console.log('opcoes',opcoesPersonalizadas)
},[opcoesPersonalizadas])

  const BuscarCategorias = () => {
    ApiAdmin.get('categorias/', {

    })
      .then(function (response) {
        // manipula o sucesso da requisição
        setCategoria(response.data.data)
        console.log(response)

      })
      .catch(function (error) {
        // manipula erros da requisição
        console.error(error);
      })
  }

  const BuscarItens = () => {
    ApiAdmin.get('itens/', {

    })
      .then(function (response) {
        // manipula o sucesso da requisição
        setAdicionais(response.data.data)
        console.log(response)


      })
      .catch(function (error) {
        // manipula erros da requisição
        console.error(error);
      })
  }

  const CadastrarProduto = (event) => {
    event.preventDefault()

    if (!titulo || titulo.trim() === "") {
      alert('O campo título está vazio!')
      return;
    }

    ApiAdmin.post('produto/create', {
      titulo: titulo.trim(),
      descricao: descricao,
      valor: valor,
      categoriaId: categoriaID,
      img: img,
      opcoespersonalizada: opcoesPersonalizadas

    }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(function (response) {
      setTitulo('')
      setDescricao('')
      setImg(null)
      inputFileRef.current.value = '';
      setValor('')
      setCategoriaSelecionada('')
      setOpenNotificacao(true)
      setNotificacao(true)

      console.log(response)
    }).catch(function (error) {
      console.log(error)
      setOpenNotificacao(true)
      setNotificacao(false)
    })


  }

  const adicionarOpcaoPersonalizada = () => {
    setOpcoesPersonalizadas([...opcoesPersonalizadas, { opcoespersonalizada: '', qtd: '', obrigatorio: 'false', itensAdicionais: [] }]);
  };

  const removerOpcaoPersonalizada = (index) => {
    const novasOpcoes = [...opcoesPersonalizadas];
    novasOpcoes.splice(index, 1);
    setOpcoesPersonalizadas(novasOpcoes);
  };

  return (
    <Modal open={openModal} onClose={closeModal}>
      <Box sx={{ height: '100vh', width: "100vw", overflow: 'auto', bgcolor: '#fff' }}>
        <DrawerMenu>
          <Box>
            <Typography sx={{ textAlign: 'center', fontWeight: 'bolder', fontSize: 20, p: 2 }}>Cadastro de Produto</Typography>
            <Button onClick={closeModal} sx={{ position: 'absolute', top: 10, right: 20 }}><CloseIcon /></Button>
            <Box component={'form'} onSubmit={CadastrarProduto} sx={{ display: 'flex', flexDirection: 'column', gap: 5, }}>

              {/* Campos existentes do formulário */}
              <TextField
                label='Titulo'
                onChange={(e) => setTitulo(e.target.value)}
                required
                value={titulo || ''}
              />

              <TextField
                label='Descricao'
                onChange={(e) => setDescricao(e.target.value)}
                value={descricao || ''}
              />

              <TextField
                label='valor'
                onChange={(e) => setValor(e.target.value)}
                required
                value={valor || ''}
              />

              <TextField
                select
                label="Selecione a Categoria"
                value={categoriaID || ""}
                required
                onChange={(e) => setCategoriaSelecionada(e.target.value)}
              >
                {categoria.length > 0 ? (
                  categoria.map((index) => (
                    <MenuItem key={index.id} value={index.id}>
                      {index.nome}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="" disabled>
                    Nenhuma Categoria Cadastrada
                  </MenuItem>
                )}
              </TextField>

              {/* Campos para opções personalizadas */}
              {opcoesPersonalizadas.map((opcao, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <TextField
                    label={`Pergunta ${index + 1}`}
                    value={opcao.opcoespersonalizada}
                    onChange={(e) => {
                      const novasOpcoes = [...opcoesPersonalizadas];
                      novasOpcoes[index].opcoespersonalizada = e.target.value;
                      setOpcoesPersonalizadas(novasOpcoes);
                    }}
                  />
                  <TextField
                    label={`Quantidade ${index + 1}`}
                    value={opcao.qtd}
                    onChange={(e) => {
                      const novasOpcoes = [...opcoesPersonalizadas];
                      novasOpcoes[index].qtd = e.target.value;
                      setOpcoesPersonalizadas(novasOpcoes);
                    }}
                  />

                  <Autocomplete
                    multiple
                    sx={{ width: '70%' }}
                    options={adicionais}
                    getOptionLabel={(option) => option.titulo}
                    value={opcao.itensAdicionais.id}
                    onChange={(event, newValue) => {
                      const novasOpcoes = [...opcoesPersonalizadas];
                      novasOpcoes[index].itensAdicionais = newValue.map(item => item.id); // Extrai apenas os IDs
                      setOpcoesPersonalizadas(novasOpcoes);
                    }}
                    renderInput={(params) => <TextField {...params} label={`Itens Adicionais ${index + 1}`} />}
                  />
                  <FormControlLabel
                    control={<Checkbox  />}
                    label="Obrigatório?"
                    onChange={(e) => {
                      const novasOpcoes = [...opcoesPersonalizadas];
                      novasOpcoes[index].obrigatorio = e.target.checked;
                      setOpcoesPersonalizadas(novasOpcoes);
                    }}
                  />
                  <Button onClick={() => removerOpcaoPersonalizada(index)}><DeleteIcon /></Button>
                </Box>
              ))}


              {/* Botão para adicionar opção personalizada */}
              <Button variant="contained" sx={{ bgcolor: 'green' }} onClick={adicionarOpcaoPersonalizada}>Adicionar opção personalizada</Button>

              {/* Campo para inserir imagem */}
              <TextField
                inputProps={{ ref: inputFileRef }}
                type="file"
                onChange={(e) => setImg(e.target.files[0])}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Insira uma imagem"
              />
              <Button variant="contained" type="submit">Salvar</Button>
            </Box>
            <NotificaoSnack openNotificao={openNotificacao} requisicaoOk={notificacao} closeNotificacao={() => setOpenNotificacao(false)}>
              <Typography>{notificacao ? 'Produto cadastrado com sucesso' : 'Nome do produto já existe!'}</Typography>
            </NotificaoSnack>
          </Box>
        </DrawerMenu>
      </Box>
    </Modal>
  );
};

