import { Box, Button, Drawer, Link, List, ListItemButton, Typography} from "@mui/material"


import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import CreateIcon from '@mui/icons-material/Create';
import CategoryIcon from '@mui/icons-material/Category';
import { ValidaLogin } from "../config/ValidaLogin";
export const DrawerMenu = ({children})=>{

   
    const icones=[
        {
            nome:'Pedidos',
            icone:<ReceiptLongIcon/>,
            link:'/admin/home'
        },
        {
            nome:'Cardapio',
            icone:<RestaurantMenuIcon/>,
            link:'/admin/produto'
        },
        {
            nome:'Cadastrar Categoria',
            icone:<CategoryIcon/>,
            link:'/admin/cadastro/categoria'
        },
        {
            nome:'Cadastrar Item Adicional',
            icone:<AutoStoriesIcon/>,
            link:'/admin/cadastro/adicionais'
        },    
        {
            nome:'Taxas Delivery',
            icone:<TwoWheelerIcon/>,
            link:'/admin/cadastro/taxas'
        },
        
    ]

    return(
        
        
       <Box>
       
        
           
         
         <Drawer
          variant="permanent"
          anchor="left"
        
         >
          <Box sx={{width:'250px'}}>
            <Typography sx={{textAlign:'center',p:2,fontWeight:'bolder',fontSize:20}} >Painel Adiministrador</Typography>
            <List>
            {icones.map((index,value)=>(
                <Link key={value} href={index.link} sx={{textDecoration:'none'}} color='inherit'>
               <ListItemButton sx={{display:'flex',gap:3,p:2}}>{index.icone}{index.nome}
               
               </ListItemButton>
               </Link>
            ))}
            
            </List>
            <Button color="inherit">Sair</Button>
          </Box>
         </Drawer>
        <Box sx={{flex:1,ml:'250px',p:2}}>
        {children}
      </Box>
    
       </Box>
      
    )
}