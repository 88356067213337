import React from 'react';
import { Box, CircularProgress, LinearProgress, Typography } from '@mui/material';

const PedidoProgresso = ({ status }) => {
  const getStatusProgress = () => {
    if (status === "Saiu para entrega") {
      return 50;
    } else if (status === "Concluído") {
      return 100;
    }
    // Caso o status não esteja definido, assume-se 25% de progresso
    return 25;
  };

  const progressValue = getStatusProgress();
  const showCircularProgress = progressValue < 100;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ position: 'relative' }}>
        {showCircularProgress && (
          <CircularProgress
            variant="determinate"
            sx={{
              color: (theme) =>
                theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
            }}
            size={40}
            thickness={4}
            value={progressValue}
          />
        )}
        {showCircularProgress && (
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
              animationDuration: '550ms',
              position: 'absolute',
              left: 0,
              [`& .MuiCircularProgress-circle`]: {
                strokeLinecap: 'round',
              },
            }}
            size={40}
            thickness={4}
          />
        )}
        <Box sx={{p:1}}>
        <Typography fontSize={24} fontWeight={'bolder'}>{status}</Typography>
        </Box>
       
      </Box>
      <br />
      <LinearProgress variant="determinate" value={progressValue} />
    </Box>
  );
};

export default PedidoProgresso;
