import { Box, Button, Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { DrawerMenu } from "../../../components/DrawerMenu"
import { NotificaoSnack } from "../../../components/NotificacaoSnack"
import { useEffect, useState } from "react"

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ModalUpdate } from "../../../components/ModalUpdate"
import { ApiAdmin } from "../../../config/ApiAdmin";


export const CadastroCategoria =()=>{
    const [openNotificacao,setOpenNotificacao]=useState()
    const [notificacao,setNotificacao]=useState()
    const [requisicaoOk,setRequisisaoOk]=useState()
    const [nome,setNome]=useState()
    const [categorias,setCategorias]=useState([])
    const [categoriaSelecionada, setCategoriaSelecionada] = useState(null);
    const [openModal,setOpenModal]=useState(false)
    const [frase,setFrase]=useState()
    const [icone,setIcone]=useState()

    useEffect(()=>{
       BuscarCategorias()
    },[setCategorias])

    const BuscarCategorias = ()=> {
        ApiAdmin.get('categorias/',{
          
        })
        
        .then(function (response) {
          setCategorias(response.data.data)
          console.log(response)
        })
        .catch(function (error) {
        
          console.error(error);
        });
    }
    const CadastrarCategoria =(event)=>{
        event.preventDefault()
        if (!nome || nome.trim() === "") {
           alert("O campo de nome está vazio!");
            return;
          }
        ApiAdmin.post('categorias/create', {
        
            nome:nome.trim(),
            frase:frase,
            icone:icone
          },{
            headers:{
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(function (response) {
           
            console.log(response);
            setNotificacao(response.data.message)
            setOpenNotificacao(true)
            setRequisisaoOk(true)
            setNome("")
            BuscarCategorias();
          
          })
          .catch(function (error) {
            setNotificacao(error.response.data.message)
            setRequisisaoOk(false)
            setOpenNotificacao(true)
            console.error(error);
          });
        }

        const DeletarCategoria = (id) => {
            ApiAdmin.delete(`categorias/delete/${id}`)
              .then(function (response) {
                console.log(response);
                setNotificacao(response.data.message)
                setRequisisaoOk(true)
                setOpenNotificacao(true)
                BuscarCategorias()
              })
              .catch(function (error) {
                setNotificacao(error.response.data.message)
                setOpenNotificacao(true)
                setRequisisaoOk(false)
                console.error(error);
              });
          }

          const PegarCategoria=(id)=>{
            setOpenModal(true)
          setCategoriaSelecionada(id)
          }

          const UpdateCategoria=(id)=>{
            if (!nome || nome.trim() === "") {
              alert("O campo de nome está vazio!");
               return;
             }
            ApiAdmin.put(`categorias/update/${id}`,{
              nome:nome.trim()
            })
            .then(function (response) {
              console.log(response);
              setNotificacao(response.data.message)
              setRequisisaoOk(true)
              setOpenNotificacao(true)
              BuscarCategorias()
              setOpenModal(false)
            })
            .catch(function (error) {
              setNotificacao(error.response.data.message)
              setOpenNotificacao(true)
              setRequisisaoOk(false)
              console.error(error);
            });
          }
        
        
    
    return (
        <DrawerMenu>
       <Container>
            <Typography sx={{ textAlign: 'center', fontWeight: 'bolder', fontSize: 20, p: 2 }}>Cadastro de Categoria</Typography>
            <Box component={'form'} onSubmit={CadastrarCategoria} sx={{ display: 'flex', flexDirection: 'column', gap: 5, }}>
                <TextField
                    label='Nome'
                    required
                    onChange={(e)=>setNome(e.target.value)}
                    value={nome || ""}
                />
                  <TextField
                    label='Frase'
                    required
                    onChange={(e)=>setFrase(e.target.value)}
                    value={frase || ""}
                />
                  <TextField
                    label='Icone'
                    required
                    type="file"
                    focused
                    onChange={(e) => setIcone(e.target.files[0])}
                    
                />
                 <Button variant="contained" type="submit">Salvar</Button>
            </Box>
        
          <NotificaoSnack openNotificao={openNotificacao} requisicaoOk={requisicaoOk} closeNotificacao={()=>setOpenNotificacao(false)}>
            <Typography>{notificacao}</Typography>
          </NotificaoSnack>
            <Box sx={{ display: 'flex', justifyContent: 'center',mt:10 }}>
            
            <TableContainer component={Paper} >
                
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Ações</TableCell>
                                <TableCell>id</TableCell>
                                <TableCell>Nome</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                        {categorias.map((valor,index)=>(
                        <TableRow key={index}>
                             <TableCell>
                                <IconButton onClick={()=>PegarCategoria(valor)}><EditIcon/></IconButton>
                               <IconButton onClick={() => DeletarCategoria(valor.id)}><DeleteIcon /></IconButton>
                                </TableCell>
                            
                             <TableCell>{valor.id}</TableCell>
                            <TableCell>{valor.nome}</TableCell>
                           
                            <ModalUpdate openModal={openModal} closeModal={()=>setOpenModal(false)}>
                              <Box sx={{bgcolor:'background.paper',width:400,height:200}}>
                                <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',gap:4}}>
                                <Typography sx={{fontSize:20,fontWeight:'bolder'}}>Digite o novo nome da categoria</Typography>
                                <TextField
                                 InputLabelProps={{
                                  shrink: true,
                              }}
                                label='Nome'
                                type="text"
                               defaultValue={categoriaSelecionada ? categoriaSelecionada.nome : ""}
                               onChange={(e)=>setNome(e.target.value)}
                                />

                                <Box sx={{display:'flex',gap:3}}>
                                <Button variant="contained" onClick={()=>UpdateCategoria(categoriaSelecionada.id)}>Atualizar</Button>
                                <Button variant="contained" onClick={()=>setOpenModal(false)}>Cancelar</Button>

                                </Box>
                                </Box>
                                </Box>
                            </ModalUpdate>
                        
                        </TableRow>
              
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            </Container>
    </DrawerMenu>
    )
}