import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { yellow } from '@mui/material/colors';
import { ModalCarrinho } from './ModalCarrinho'; // Importe o componente do modal aqui
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function MenuBottom() {
  const [value, setValue] = React.useState(0);
  const [isModalOpen, setIsModalOpen] = React.useState(false); // Estado para controlar a abertura do modal
  const navigate=useNavigate()
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const handleNavigationChange = () => {
      const path = window.location.pathname;
      if (path === '/') {
        setValue(0);
      } else if (path === '/cliente/pedido') {
        setValue(1);
      }
    };

    // Adicione um listener para ouvir as mudanças na localização da URL
    window.addEventListener('popstate', handleNavigationChange);

    // Atualize o valor com base na localização da URL quando o componente for montado
    handleNavigationChange();

    // Limpe o listener quando o componente for desmontado
    return () => {
      window.removeEventListener('popstate', handleNavigationChange);
    };
  }, []);

  const Inicio = ()=>{
    navigate('/')
  }
  const Pedido = ()=>{
    navigate('/cliente/pedido')
  }
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Atualiza o valor do BottomNavigation quando o modal é fechado
 

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        display: { md: 'block'}
      }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          width: '100%',
          bgcolor: yellow['500'],
        }}
      >
        {/* Adicione o Link para a tela inicial */}
  
          <BottomNavigationAction label="Inicio" onClick={Inicio} icon={<HomeIcon />} />
       
        <BottomNavigationAction label="Pedidos" onClick={Pedido} icon={<ReceiptLongIcon />} />
        <BottomNavigationAction label="Carrinho" icon={<ShoppingCartIcon />} onClick={handleOpenModal} />
      </BottomNavigation>
      
      {/* Modal de carrinho */}
      <ModalCarrinho open={isModalOpen} onClose={handleCloseModal} />
    </Box>
  );
}
