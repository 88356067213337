import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Modal,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  CardMedia,
  TextField,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import { CartContext } from '../context/CartProvider';

export const ModalProduto = ({ openModal, closeModal, produto }) => {
  const [complementosSelecionados, setComplementosSelecionados] = useState([]);
  const [totalValue, setTotalValue] = useState();
  const { adicionaProduto, carrinho } = useContext(CartContext);
  const [observacao, setObservacao] = useState('');
  const qtdItensObrigatorio = produto.qtditensobrigatorio;



  const handleObservacaoChange = (event) => {
    setObservacao(event.target.value);
  };

  const handleCheckboxChange = (item) => {
    let complementosAtualizados = [...complementosSelecionados];

    const index = complementosAtualizados.indexOf(item);

    if (index === -1) {
      // Verifica se a quantidade máxima de complementos já foi alcançada, apenas se qtditensobrigatorio for maior que 0
      if (produto.qtditensobrigatorio > 0 && complementosAtualizados.length >= produto.qtditensobrigatorio) {
        alert(`Você só pode adicionar no máximo ${produto.qtditensobrigatorio} complementos.`);
      } else {
        complementosAtualizados.push(item);
      }
    } else {
      complementosAtualizados.splice(index, 1);
    }

    setComplementosSelecionados(complementosAtualizados);
  };

  useEffect(() => {
    console.log('complementos', complementosSelecionados);
    setTotalValue(
      complementosSelecionados.reduce((acumulador, valor) => {
        if (valor && valor.valor) {
          return acumulador + valor.valor;
        }
        return acumulador;
      }, 0)
    );
  }, [complementosSelecionados]);

  const handleAddToCart = () => {
    if (qtdItensObrigatorio > 0 && complementosSelecionados.length < qtdItensObrigatorio) {
      alert('Selecione pelo menos ' + qtdItensObrigatorio + ' complementos!');
      return;
    }

    // Adicionar o produto ao carrinho
    adicionaProduto(produto, complementosSelecionados, observacao);
    setComplementosSelecionados([]);

    handleCloseModal();
  };

  const handleQuantidadeChange = (value) => {
    const quantidade = Math.max(0, value); // Garante que a quantidade não seja negativa
    const complementosAtualizados = [...Array(quantidade)].map(() => {
      return produto.itensAdicionais[0]; // Substitua [0] pelo índice correto do item adicional que deseja adicionar
    });
    setComplementosSelecionados(complementosAtualizados);
  };


  const handleCloseModal = () => {
    setComplementosSelecionados([]);
    setTotalValue();
    setObservacao('') // Reinicia o estado totalValue
    closeModal();
  };

  const QuantidadeComplemento = () => {
    return (
      <>
     {produto.itensAdicionais.length>0 && (

   
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => handleQuantidadeChange(complementosSelecionados.length - 1)}>
          <RemoveIcon />
        </IconButton>
        <Typography variant="body1">{complementosSelecionados.length}</Typography>
        <IconButton onClick={() => handleQuantidadeChange(complementosSelecionados.length + 1)}>
          <AddIcon />
        </IconButton>
      </Box>
        )}
      </>
    );
  };

  return (
    <Modal key={produto.id} open={openModal} onClose={handleCloseModal}>
      <Box width="100vw" height={'100vh'} display="flex" justifyContent="center" alignItems="center">
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 550, width: 550, bgcolor: '#eee', position: 'relative' }}>
          <AppBar position="relative">
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleCloseModal} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ fontSize: '18px', ml: 2, flex: 1 }}>DETALHES DO ITEM</Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ p: 2, overflow: 'auto' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CardMedia sx={{ width: 200, height: 200 }} image={`https://api.lanchedobaba.gdelivery.app.br/files/${produto.img}`} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="h6">{produto.titulo}</Typography>
              <Typography sx={{ color: 'gray' }}>{produto.descricao}</Typography>
              <Typography>R$ {produto.valor.toFixed(2)}</Typography>
            </Box>
            <Box sx={{ display: 'flex', mt: 5 }}>
              {produto.itensAdicionais.length>0 && (
                
              
              <Typography variant="h6">Complementos</Typography>
              )}
              {qtdItensObrigatorio === 0 && !produto.itensAdicionais>0 && (
                <Typography sx={{ marginLeft: 'auto' }}>{complementosSelecionados.length} selecionados</Typography>
              )}
            </Box>

            {qtdItensObrigatorio > 0 ? (
              <Box>
                {produto.itensAdicionais.map((item) => (
                  <Box key={item.id} sx={{ p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Typography>{item.titulo}</Typography>
                      <Typography>R$ {item.valor.toFixed(2)}</Typography>
                    
                    </Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => handleCheckboxChange(item)}
                          checked={complementosSelecionados.includes(item)}
                          value={item.titulo}
                        />
                      }
                    />
                  </Box>
                ))}
              </Box>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                {produto.itensAdicionais.map((item) => (
                  <Box sx={{display:'flex',gap:1}}> 
                  <Typography>{item.titulo}</Typography>
                  <Typography>R$ {item.valor.toFixed(2)}</Typography>
                  </Box>
                
                ))}

                <QuantidadeComplemento />
              </Box>
            )}

            <Typography sx={{ mt: 2 }}>Observações:</Typography>
            <TextField
              multiline
              rows={1}
              variant="outlined"
              fullWidth
              sx={{ mt: 1, mb: 2, pb: 10 }}
              placeholder="Adicione sua observação aqui"
              value={observacao}
              onChange={handleObservacaoChange}
            />

            <Box sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, bgcolor: '#fff', p: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button variant="contained" color="primary" sx={{ width: '90%' }} onClick={handleAddToCart}>
                  Adicionar R$ {(produto.valor + totalValue).toFixed(2)}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
