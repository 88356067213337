import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomeAdmin } from "./pages/admin/home/HomeAdmin";
import { Cardapio } from "./pages/admin/cardapio/Cardapio";
import { CadastroCategoria } from "./pages/admin/cadastroCategoria/CadastroCategoria";
import { CadastroItemAdicional } from "./pages/admin/cadastroItemAdicional/CadastroItemAdicional";
import { HomeCliente } from "./pages/cliente/HomeCliente";
import { CadastroTaxasDelivery } from "./pages/admin/TaxasDelivery/CadastroTaxasDelivery";
import { Login } from "./pages/admin/login/Login";
import { Pedidos } from "./pages/cliente/PedidoFinalizado/Pedidos";
import { useLocalStorageListener } from "./config/localStorageUtils";

function App() {
  useLocalStorageListener();
  return (
    <div>

      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/admin/home" element={<HomeAdmin />} />
          <Route path="/admin/cadastro/categoria" element={<CadastroCategoria />} />
          <Route path="/admin/cadastro/adicionais" element={<CadastroItemAdicional />} />
          <Route path="/admin/cadastro/taxas" element={<CadastroTaxasDelivery />} />
          <Route path="/admin/produto" element={<Cardapio />} />
          <Route path="/cliente/pedido" element={<Pedidos />} />
          <Route path="/" element={<HomeCliente />} />

        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
