
import { Modal, Typography, Button, Box, TextField, CardMedia, Container, IconButton, Autocomplete } from "@mui/material";
import { DrawerMenu } from "./DrawerMenu";
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from "react";
import { NotificaoSnack } from "./NotificacaoSnack";
import { ApiAdmin } from "../config/ApiAdmin";


export const ModalEditarProduto = ({ openModal, closeModal, produto }) => {

  const [autocompleteKey, setAutocompleteKey] = useState(0);
  const [adicionais, setAdicionais] = useState([])
  const [titulo, setTitulo] = useState()
  const [descricao, setDescricao] = useState()
  const [valor, setValor] = useState()
  const [img, setImg] = useState()
  const [openNotificacao, setOpenNotificacao] = useState()
  const [notificacao, setNotificacao] = useState()
  const [itensAdicionado, setItensAdicionado] = useState([])
  const [novosAdicionais, setNovosAdicionais] = useState([])
  const [qtditensobrigatorio, setQtditensobrigatorio] = useState();



  const AtualizarProduto = (id) => {
    ApiAdmin.put('produto/update/' + id, {
      titulo: titulo,
      descricao: descricao,
      valor: valor,
      img: img,
  
    }, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }

    }).then(function (response) {
      setNotificacao(true);
      setOpenNotificacao(true);
      console.log(response);
    }).catch(function (error) {
      setOpenNotificacao(true);
      setNotificacao(false);
      console.log(error);
    });
  }



  return (
    <Modal open={openModal} onClose={closeModal}>


      <Box sx={{ height: '100vh', width: "100vw", overflow: 'auto', bgcolor: '#fff' }}>
        <DrawerMenu>
          <Container>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}>


              <Box sx={{ height: 250, width: 250 }}>
                <CardMedia sx={{ width: '100%', height: '100%' }} image={"https://api.lanchedobaba.gdelivery.app.br/files/" + produto.img} />
              </Box>

              <Box sx={{ display: 'flex', flexDirection: "column", width: '100%', pt: 5, gap: 3 }}>


                <TextField
                  label='titulo'
                  defaultValue={produto.titulo}
                  onChange={(e) => setTitulo(e.target.value)}
                />

                <TextField
                  label='descricao'
                  defaultValue={produto.descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                />
                <TextField
                  label='valor'
                  defaultValue={produto.valor && produto.valor.toFixed(2)}
                  onChange={(e) => setValor(e.target.value)}
                />
                <TextField

                  type="file"
                  onChange={(e) => setImg(e.target.files[0])}
                />

             
              </Box>

            </Box>


            <Typography variant="h5" sx={{ paddingY: 5 }}>Complementos</Typography>
     
    
            <Button variant="contained" sx={{ width: '100%', mt: 2 }} onClick={() => AtualizarProduto(produto.id)}>Atualizar</Button>
            <NotificaoSnack openNotificao={openNotificacao} requisicaoOk={notificacao} closeNotificacao={() => setOpenNotificacao(false)}>
              <Typography>{notificacao ? 'Produto Atualizado com sucesso' : 'Erro ao atualizar produto!'}</Typography>
            </NotificaoSnack>
          </Container>
        </DrawerMenu>
      </Box>

    </Modal>
  );
};
