import { useEffect, useState } from "react";
import { Box, Button, Container, Tab, Tabs, Typography } from "@mui/material";
import { ApiCliente } from "../../../config/ApiCliente";
import MenuBottom from "../../../components/MenuBottom";
import PedidoProgresso from "../../../components/PedidoProgresso";
import { io } from "socket.io-client";
import { FormLogin } from "../../../components/FormLogin";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { DateTime } from "luxon";
export const Pedidos = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [pedidosCliente, setPedidosCliente] = useState([]);
  const cliente = JSON.parse(localStorage.getItem('cliente'));
  const logado = localStorage.getItem('logado');
  const [fetchPedidos, setFetchPedidos] = useState(false);
  const [abrirFormLogin, setAbrirFormLogin] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(state => state.token);
  const [isLoading, setIsLoading] = useState(true);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const formatTimeInManaus = (dateString) => {
    const date = DateTime.fromISO(dateString, { zone: "America/Fortaleza" });
    return date.toLocaleString(DateTime.DATETIME_SHORT);
  };
  
  const currentTimeInManaus = formatTimeInManaus(new Date().toISOString());
 


  useEffect(() => {
    
    buscarPedidosEmAndamento();

    // Configurar o Socket.io no cliente
    const socket = io('https://api.lanchedobaba.gdelivery.app.br');

    socket.on('statusAtualizado', (data) => {
      // Atualizar as categorias quando houver atualização de produtos
      buscarPedidosEmAndamento();
    });

    return () => {
      socket.disconnect(); // Desconectar o socket quando o componente for desmontado
    };
  }, [token]);



  const buscarPedidosEmAndamento = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.error("Token não encontrado. Faça login para acessar os pedidos.");
      return;
    }

    if (cliente?.data) {
      ApiCliente.get("/pedidos/cliente/" + cliente.data.id, {
        headers: {
          authorization: token,
        },
      })
        .then(function (response) {
          setPedidosCliente(response.data.data);
          setIsLoading(false);
          console.log(response);
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      console.error("Cliente não encontrado ou dados do cliente não estão disponíveis. Não é possível buscar os pedidos.");
    }
  };


  const pedidosEmAndamento = pedidosCliente.filter((pedido) => pedido.status === 'Em andamento' || pedido.status === "Saiu para entrega").reverse();
  const pedidosConcluidos = pedidosCliente.filter((pedido) => pedido.status === 'Concluído').reverse();

  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Em andamento" />
          <Tab label="Concluídos" />
        </Tabs>
      </Box>
      {isLoading && logado? (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
    <CircularProgress />
      </Box>
      ):(

    
      <Box>
      {activeTab === 0 && !logado && (
        <Container>
          <Box
            sx={{
              height: '100vh',
              bgcolor: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button variant="outlined" onClick={() => setAbrirFormLogin(true)} sx={{ fontSize: '16px', mt: 2 }}>
                Entre para visualizar seus pedidos
              </Button>
              <FormLogin open={abrirFormLogin} onClose={() => setAbrirFormLogin(false)} />
            </Box>
          </Box>
        </Container>
      )}
      {activeTab === 1 && !logado && (
        <Container>
          <Box
            sx={{
              height: '100vh',
              bgcolor: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
              <Button variant="outlined" onClick={() => setAbrirFormLogin(true)} sx={{ fontSize: '16px', mt: 2 }}>
                Entre para visualizar seus pedidos
              </Button>
              <FormLogin open={abrirFormLogin} onClose={() => setAbrirFormLogin(false)} />
            </Box>
          </Box>
        </Container>
      )}
      {activeTab === 0 && logado && (
        <Container>
          {pedidosEmAndamento.length===0 ? (
    <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',height:'100vh'}}>
      <Box>
        
      </Box>
    <Typography variant="h6"  sx={{ mt: 5 }}>
    Você não tem pedidos em andamento ;)
  </Typography>
  </Box>
          ):(
            pedidosEmAndamento.map((pedido) => (
              <Box key={pedido.id} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', mt: 5, boxShadow: 2, mb: 10 }}>
                <PedidoProgresso status={pedido.status} />
                <Box sx={{ width: 80, height: 80, p: 2 }}>
                  <img src="/logo.png" style={{ width: '100%', height: '100%' }} alt="Logo" />
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography sx={{ fontSize: 18, fontWeight: 'bolder' }}>Pedido #{pedido.codigopedido}</Typography>
                  <Button variant="contained" sx={{ mt: 2 }}>Ver pedido</Button>
                </Box>
                <Box sx={{ borderTop: "1px solid #eee", mt: 5, p: 1, display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 18, fontWeight: 'bolder' }}>Pedido Feito: {formatTimeInManaus(pedido.createdAt)}</Typography>
                </Box>
                <Box sx={{ borderTop: "1px solid #eee", mt: 5, p: 1 }}>
                  <Typography sx={{ fontSize: 18 }}>Entrega em</Typography>
                  <Typography sx={{ fontSize: 15, fontWeight: 'bolder' }}>{pedido.cliente.rua}</Typography>
                </Box>
                <Box sx={{ borderTop: "1px solid #eee", mt: 5, p: 1 }}>
                  <Typography sx={{ fontSize: 18 }}>Forma de pagamento</Typography>
                  <Typography sx={{ fontSize: 15, fontWeight: 'bolder' }}>{pedido.formadepagamento}</Typography>
                </Box>
              </Box>
            ))
          )
        }
         
        </Container>
      )}
      {activeTab === 1 && logado && (
        <Container>
          {pedidosConcluidos.length===0 ?(
           <Box sx={{display:'flex',alignItems:'center',justifyContent:'center',height:'100vh'}}>
            <Typography variant="h6" align="center" sx={{ mt: 5 }}>
            Você não tem nenhum pedido concluído ainda :(
          </Typography>
          </Box>
          ) :(
            pedidosConcluidos.map((pedido) => (
              <Box key={pedido.id} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', mt: 5, boxShadow: 2, mb: 10 }}>
                <PedidoProgresso status={pedido.status} />
                <Box sx={{ width: 80, height: 80, p: 2 }}>
                  <img src="/logo.png" style={{ width: '100%', height: '100%' }} alt="Logo" />
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography sx={{ fontSize: 18, fontWeight: 'bolder' }}>Pedido #{pedido.codigopedido}</Typography>
                  <Button variant="contained" sx={{ mt: 2 }}>Ver pedido</Button>
                </Box>
                <Box sx={{ borderTop: "1px solid #eee", mt: 5, p: 1, display: 'flex',flexDirection:'column', justifyContent: 'center', alignItems: 'start' }}>
                 <Typography sx={{ fontSize: 18, fontWeight: 'bolder' }}>Pedido Feito: {formatTimeInManaus(pedido.createdAt)}</Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: 'bolder',mt:5}}>Pedido Finalizado em: {formatTimeInManaus(pedido.horaconclusao)}</Typography>
                </Box>
                <Box sx={{ borderTop: "1px solid #eee", mt: 5, p: 1 }}>
                  <Typography sx={{ fontSize: 18 }}>Entrega em</Typography>
                  <Typography sx={{ fontSize: 15, fontWeight: 'bolder' }}>{pedido.cliente.rua}</Typography>
                </Box>
                <Box sx={{ borderTop: "1px solid #eee", mt: 5, p: 1 }}>
                  <Typography sx={{ fontSize: 18 }}>Forma de pagamento</Typography>
                  <Typography sx={{ fontSize: 15, fontWeight: 'bolder' }}>{pedido.formadepagamento}</Typography>
                </Box>
              </Box>
            ))
          )
        
        }
         
        </Container>
      )}
  </Box>
    )
  }
      <MenuBottom />
    </Box>
  );
};
