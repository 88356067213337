// authReducer.js
const initialState = {
  token: localStorage.getItem('token') || null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TOKEN':
      return { ...state, token: action.payload };
    case 'UPDATE_TOKEN':
      return { ...state, token: action.payload };
    case 'CLEAR_TOKEN':
      return { ...state, token: null }; // Adicione um case para limpar o token
    default:
      return state;
  }
};

export default authReducer;
