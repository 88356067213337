import React, { useContext, useEffect, useState, useRef } from 'react';
import { Modal, Box, Typography, Button, IconButton, TextField } from '@mui/material';
import { ArrowDownward, Add, Remove } from '@mui/icons-material';
import { ApiCliente } from '../config/ApiCliente';
import { FormPagamento } from './FormPagamento';
import { FormLogin } from './FormLogin';
import { CartContext, FormaDePagamentoContext } from '../context/CartProvider';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MapIcon from '@mui/icons-material/Map';
import EditIcon from '@mui/icons-material/Edit';
import { v4 as uuidv4 } from 'uuid';
import { FormEditEndereco } from './FormEditEndereco';
import { useNavigate } from 'react-router-dom';
import { ValidaLogin } from '../config/ValidaLogin';
import { useSelector } from 'react-redux';



export const ModalCarrinho = ({ open, onClose }) => {
  const { carrinho, setCarrinho, adicionaProduto, removeProduto } = useContext(CartContext);
  const [valorTotal, setValorTotal] = useState(0);
  const [abrirFormLogin, setAbrirFormLogin] = useState(false);
  const [abrirFormPagamento, setAbrirFormPagamento] = useState(false);
  const [taxaEntrega, setTaxaEntrega] = useState(0);
  const [deliveryOption, setDeliveryOption] = useState();
  const { formasPagamento, formaPagamentoSelecionada } = useContext(FormaDePagamentoContext);
  const cliente = JSON.parse(localStorage.getItem('cliente'));
  const [status, setStatus] = useState('Em andamento');
  const [abrirFormEdit, setAbrirFormEdit] = useState(false);
  const navigate = useNavigate();
  const token = useSelector(state => state.token);
  const [troco, setTroco] = useState();





  const handleCalcularDistancia = () => {
 
    if (!token) {
      console.error('Cliente não encontrado ou dados do cliente não estão disponíveis.');
      return;
    }

    const clienteLatitude = cliente && cliente.data && cliente.data.lat ? cliente.data.lat : 0;
    const clienteLongitude = cliente && cliente.data && cliente.data.long ? cliente.data.long : 0;
    
    

    const estabelecimentoLatitude = -3.103805267701544; /* COLOQUE AQUI A LATITUDE DO ESTABELECIMENTO */
    const estabelecimentoLongitude = -60.06154406836274; /* COLOQUE AQUI A LONGITUDE DO ESTABELECIMENTO */

    if (token) {
      ApiCliente.post('calcular-distancia', {
        clienteLatitude: clienteLatitude,
        clienteLongitude: clienteLongitude,
        estabelecimentoLatitude: estabelecimentoLatitude,
        estabelecimentoLongitude: estabelecimentoLongitude,
      })
        .then((response) => {
          const { distance } = response.data;
          setTaxaEntrega(distance * 1.5);
          console.log('Distância:', distance);
        })
        .catch((error) => {
          console.error('Erro ao calcular distância:', error);
        });
    } else {
      setTaxaEntrega(0);
    }
  };

  useEffect(() => {
    if (!token) {
      setTaxaEntrega(0)
    }
  }, [token])



  useEffect(() => {
    if (cliente && cliente.data) {
      handleCalcularDistancia();
    }
    
  
    const total = carrinho.reduce((acumulador, produto) => acumulador + produto.valorTotal, 0);
    const valorTotalComTaxa = total + taxaEntrega;
    setValorTotal(valorTotalComTaxa);


    // Passo 3: Scroll the container when content is updated
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [carrinho, setCarrinho, deliveryOption, cliente, taxaEntrega, valorTotal, formaPagamentoSelecionada, token]);

  const ConcluirPedido = () => {

    const codigoPedido = uuidv4().substr(0, 5);
    if (troco === undefined && formaPagamentoSelecionada.nome === "Dinheiro") {
      alert('por favor, digite a quantide de troco')
    } else {


      ApiCliente.post('pedidos/create', {
        clienteId: cliente.data.id,
        codigopedido: codigoPedido,
        status: status,
        taxaentrega: taxaEntrega,
        formadepagamento: formaPagamentoSelecionada.nome,
        deliveryoption: deliveryOption,
        carrinho: carrinho,
        troco: troco,
        valorTotal: valorTotal,
      }, {
        headers: {
          "authorization": token
        }
      }).then(function (response) {
        navigate('/cliente/pedido');
        setCarrinho([])
        console.log(response);
      })
        .catch(function (error) {
          console.error('Erro na requisição:', error.response);
          alert('erro na conexão, sua internet pode não está funcionando corretamente, por favor, tente novamente')
          localStorage.clear()
          navigate('/')

        });

    }
  }
  // Passo 1: Criar a referência (ref)
  const contentRef = useRef(null);

  return (
    <Modal open={open} onClose={onClose}>


      <Box
        ref={contentRef}
        sx={{
          position: 'absolute',
          overflowX: 'hidden',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          bgcolor: '#f5f5f5',
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',

        }}
      >
        <Box sx={{ position: 'sticky', top: 0, zIndex: 2, boxShadow: 2, p: 1, bgcolor: '#fff' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: '30px',
                height: '100%',
                borderRadius: '50%',
                bgcolor: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={onClose}
            >
              <ArrowDownward />
            </Box>
            <Typography variant="h6" sx={{ ml: 4 }}>
              Seu Carrinho
            </Typography>
          </Box>
        </Box>

        <Box sx={{}}>
          {carrinho.length === 0 ? (
            <Box
              sx={{ height: '100%', bgcolor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ textAlign: 'center' }}>
                  Seu carrinho está vazio.
                </Typography>
                <Button variant="contained" onClick={onClose}>
                  Voltar para o cardápio
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              <Box sx={{ p: 3, bgcolor: '#fff' }}>
                <Typography sx={{ color: '#032351', fontWeight: '700', mb: 1, fontSize: '22px' }}>Lanche do Babá</Typography>
                {carrinho.map((produto, index) => (
                  <Box key={`${produto.id}-${index}`} sx={{ borderTop: '1px solid #ccc', display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Box sx={{ paddingTop: '10px' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', mt: '10px', gap: 1 }}>
                        {cliente && cliente.data && (
                          <Typography sx={{ fontSize: '16px' }}>{produto.qtd}x {produto.titulo}</Typography>
                        )}
                        {produto.complementos.map((complemento) => (
                          <Typography key={complemento.id} sx={{ fontSize: '14px', color: '#888' }}>{complemento.qtd}x {complemento.titulo}</Typography>
                        ))}
                        <Typography sx={{ fontWeight: 'bolder', fontSize: '14px' }}>R$ {produto.valorTotal.toFixed(2)}</Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '10px' }}>
                      <IconButton color="primary" onClick={() => removeProduto(produto, produto.observacao)}>
                        <Remove />
                      </IconButton>
                      <Typography sx={{ mx: 1 }}>{produto.qtd}</Typography>
                      <IconButton color="primary" onClick={() => adicionaProduto(produto, produto.complementos, produto.observacao)}>
                        <Add />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, p: 3, gap: 1, bgcolor: '#fff' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', color: '#888' }}>
                  <Typography sx={{ fontSize: '16px' }}>Subtotal</Typography>
                  <Typography sx={{ fontSize: '15px' }}>R$ {(valorTotal - taxaEntrega).toFixed(2)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', color: '#888' }}>
                  <Typography sx={{ fontSize: '16px' }}>Taxa de entrega</Typography>
                  <Typography sx={{ fontSize: '15px' }}>R$ {taxaEntrega.toFixed(2)}</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', color: '#032351' }}>
                  <Typography sx={{ fontSize: '22px', fontWeight: 'bolder' }}>Total</Typography>
                  <Typography sx={{ fontSize: '22px', fontWeight: 'bolder' }}>R$ {valorTotal.toFixed(2)}</Typography>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, p: 3, gap: 1, bgcolor: '#fff' }}>
                <Typography sx={{ color: '#032351', fontSize: '22px', fontWeight: 'bolder' }}>Formas de pagamento</Typography>
                {token ? (
                  <Button variant="outlined" sx={{ fontSize: '16px', mt: 2 }} onClick={() => setAbrirFormPagamento(true)}>
                    {formaPagamentoSelecionada ? formaPagamentoSelecionada.nome : "Escolha a forma de pagamento"}
                    {formaPagamentoSelecionada && formaPagamentoSelecionada.icone && (
                      <IconButton>{formaPagamentoSelecionada.icone}</IconButton>
                    )}
                  </Button>
                ) : (
                  <Button variant="outlined" onClick={() => setAbrirFormLogin(true)} sx={{ fontSize: '16px', mt: 2 }}>
                    Entre para adicionar forma de pagamento
                  </Button>
                )}
                {formaPagamentoSelecionada && formaPagamentoSelecionada.nome === 'Dinheiro' && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>

                    <TextField
                      value={troco}
                      onChange={(e) => setTroco(e.target.value)}
                      label='Troco'
                      placeholder='ex:50'
                      type="number"
                      variant="outlined"
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Box>
                )}
              </Box>

              <FormLogin open={abrirFormLogin} onClose={() => setAbrirFormLogin(false)} />
              <FormPagamento open={abrirFormPagamento} onClose={() => setAbrirFormPagamento(false)} />

              {formaPagamentoSelecionada && (
                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, p: 3, gap: 1, bgcolor: '#fff' }}>
                  <Typography sx={{ color: '#032351', fontSize: '22px', fontWeight: 'bolder' }}>
                    Como prefere receber seu pedido?
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
                    <Button
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        border: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '120px',
                        backgroundColor: deliveryOption === 'Entrega' ? '#f5f5f5' : 'transparent',
                      }}
                      onClick={() => setDeliveryOption('Entrega')}
                    >
                      <DeliveryDiningIcon sx={{ fontSize: '50px' }} />
                      <Typography>Entrega</Typography>
                    </Button>
                    <Button
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        border: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '120px',
                        backgroundColor: deliveryOption === 'Retirada' ? '#f5f5f5' : 'transparent',
                      }}
                      disabled
                      onClick={() => setDeliveryOption('Retirada')}
                    >
                      <StoreMallDirectoryIcon sx={{ fontSize: '30px' }}  />
                      <Typography>Retirar no local</Typography>
                    </Button>
                  </Box>
                  <ValidaLogin>
                    {deliveryOption === 'Entrega' && (
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <MonetizationOnIcon sx={{ fontSize: '20px', color: '#032351', fontWeight: 'bolder' }} />
                          <Typography sx={{ fontSize: '14px', color: '#798289', fontWeight: 'bolder' }}>Taxa de entrega:</Typography>
                          <Typography sx={{ fontSize: '14px', fontWeight: 'bolder', color: '#032351' }}>R$ {taxaEntrega.toFixed(2)}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 3 }}>
                          <Typography sx={{ fontWeight: 'bolder', color: '#032351', fontSize: '20px' }}>Local da entrega:</Typography>
                          <Box sx={{ display: 'flex', gap: 1, mt: 4, borderTop: '1px solid #ccc', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', height: '100px' }}>
                              <MapIcon color="primary" />
                              <Typography sx={{ fontSize: '14px', color: '#032351', fontWeight: 'bolder' }}>{cliente.data.rua}, {cliente.data.numero}</Typography>
                              <Typography sx={{ fontSize: '14px', color: '#032351', fontWeight: 'bolder' }}>{cliente.data.bairro} {cliente.data.complemento}</Typography>
                            </Box>
                            <IconButton onClick={() => setAbrirFormEdit(true)}>
                              <EditIcon />
                            </IconButton>
                            <FormEditEndereco open={abrirFormEdit} onClose={() => setAbrirFormEdit(false)} />
                          </Box>

                          <Button variant="contained" onClick={ConcluirPedido}>
                            Concluir Pedido
                          </Button>

                        </Box>
                      </Box>
                    )}
                  </ValidaLogin>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
