import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import { ArrowDownward } from "@mui/icons-material";

export const ModalPedido = ({ open, onClose, pedido }) => {
  const dataPedido = new Date(pedido.createdAt);
  const formattedDate = dataPedido.toLocaleDateString("pt-BR");
  const formattedTime = dataPedido.toLocaleTimeString("pt-BR", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <Modal open={open} onClose={onClose}>

      
      
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", lg: "600px" },
          minHeight: { xs: "100%", lg: "500px" },
          bgcolor: "#f5f5f5",
        }}
      >
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            boxShadow: 2,
            p: 1,
            bgcolor: "#fff",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: "30px",
                height: "100%",
                borderRadius: "50%",
                bgcolor: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={onClose}
            >
              <ArrowDownward />
            </Box>
            <Typography variant="h6" sx={{ ml: 4 }}>
              Pedido #{pedido.codigopedido}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: 1,
            alignItems: "center",
            bgcolor: "#fff",
          }}
        >
          <Box>
            <Typography sx={{ fontSize: "14px", fontWeight: "bolder" }}>
              {pedido.cliente.nome}
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#888", fontWeight: "bolder" }}>
              {pedido.cliente.telefone}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px" }}>Data do Pedido: {formattedDate}</Typography>
            <Typography sx={{ fontSize: "12px" }}>Hora do Pedido: {formattedTime}</Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", mt: 2, p: 1, bgcolor: "#fff" }}>
          <Typography sx={{fontSize:'16px',fontWeight:'bolder'}}>Entregar em:</Typography>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{fontSize:'12px',fontWeight:'bolder'}}>{pedido.cliente.rua},</Typography>
            <Typography  sx={{fontSize:'12px',fontWeight:'bolder'}}>{pedido.cliente.numero}</Typography>
          </Box>
          <Typography  sx={{fontSize:'12px',fontWeight:'bolder'}}>{pedido.cliente.complemento}</Typography>
          <Typography  sx={{fontSize:'12px',fontWeight:'bolder'}}>{pedido.cliente.bairro}</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", p: 1, bgcolor: "#fff", mt: 2 }}>
          <Typography sx={{fontSize:'16px',fontWeight:'bolder'}}>Pedido:</Typography>
          {pedido.carrinho.map((item, index) => (
            <Box key={index} sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography  sx={{fontSize:'14px',fontWeight:'bolder'}}>{item.qtd}x {item.titulo}</Typography>
                
                <Typography sx={{fontSize:'14px',fontWeight:'bolder'}}>R$ {item.valor.toFixed(2)}</Typography>
              </Box>
              {item.observacao && (
              <Typography sx={{fontSize:'14px',fontWeight:'bolder',color:'red'}}>obs:{item.observacao}</Typography>
              )}
              {item.complementos.map((complemento, idx) => (
                <Box key={idx} sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box>
                    <Typography sx={{color:'#888',fontWeight:'bolder',fontSize:'14px'}}>{complemento.qtd}x {complemento.titulo}</Typography>
                  </Box>
                  <Box>
                    <Typography  sx={{fontSize:'12px',fontWeight:'bolder',color:'#888'}}>R$ {complemento.valor.toFixed(2)}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{fontSize:'14px',fontWeight:'bolder'}}>Total:</Typography>
            <Typography sx={{fontSize:'14px',fontWeight:'bolder'}}>R$ {(pedido.valorTotal - pedido.taxaentrega).toFixed(2)}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1,bgcolor:'#fff',mt:2 }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Taxa de entrega</Typography>
          <Typography sx={{ fontSize: '12px', fontWeight: 'bolder' }}>R$ {parseFloat(pedido.taxaentrega).toFixed(2)}</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1,bgcolor:'#fff',mt:2 }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Valor Total</Typography>
          <Typography sx={{ fontSize: '12px', fontWeight: 'bolder' }}>R$ {pedido.valorTotal.toFixed(2)}</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1,bgcolor:'#fff',mt:2 }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Troco</Typography>
          <Typography sx={{ fontSize: '12px', fontWeight: 'bolder' }}>R$ {pedido.troco}</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1,bgcolor:'#fff',mt:2 }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Forma de pagamento</Typography>
          <Typography sx={{ fontSize: '12px', fontWeight: 'bolder' }}>{pedido.formadepagamento}</Typography>
        </Box>
      </Box>
   
    </Modal>
  );
};
