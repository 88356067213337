import React, { useContext, useEffect, useState } from 'react';
import { Box, Badge, Typography, IconButton } from "@mui/material";
import { ShoppingCart } from '@mui/icons-material';
import { CartContext } from '../context/CartProvider';
import { ModalCarrinho } from './ModalCarrinho';
import { yellow } from '@mui/material/colors';

export const BotaoVerCarrinho = () => {
  const { carrinho, adicionaProduto, removeProduto } = useContext(CartContext);
  const [valorTotal, setValorTotal] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Calcula o valor total sempre que o carrinho for atualizado
    const total = carrinho.reduce((acumulador, produto) => acumulador + produto.valorTotal, 0);
    setValorTotal(total);
  }, [carrinho]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Box sx={{ position: 'fixed', bottom: { xs: 60, md: 65 }, right: 20, zIndex: 1 }}>
        
      <Badge badgeContent={carrinho.length} color="primary">
  <IconButton
    onClick={handleOpen}
    sx={{
      bgcolor: yellow[800],
      p: 2,
      '&:hover': {
        bgcolor: yellow[900], // Define a cor de fundo ao passar o mouse
      },
    }}
  >
    <ShoppingCart fontSize="medium" sx={{ color: '#000' }} />
  </IconButton>
</Badge>

        
      </Box>

      <ModalCarrinho open={open} onClose={handleClose} carrinho={carrinho} adicionaProduto={adicionaProduto} removeProduto={removeProduto} />
    </React.Fragment>
  );
};
