import { ArrowDownward } from '@mui/icons-material';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { ApiCliente } from '../config/ApiCliente';
import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
import { setToken } from '../redux/authActions';
import { CircularProgress } from '@mui/material';



export const FormCadastroCliente = ({ open, onClose }) => {
  const [isFormOpen, setFormOpen] = useState(false);
  const [isAddressFormOpen, setAddressFormOpen] = useState(false);
  const [telefone, setTelefone] = useState('');
  const [nome, setNome] = useState('');
  const [cep, setCep] = useState('');
  const [bairro, setBairro] = useState('');
  const [rua, setRua] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const CadastrarCliente = () => {
    ApiCliente.post('clientes/cadastro', {
      nome: nome,
      telefone: telefone,
      rua: rua,
      numero: numero,
      bairro: bairro,
      cep: cep,
      complemento: complemento,
      long: longitude,
      lat: latitude
    })
      .then(function (response) {
        getClienteTelefone();
        EfetuarLogin();
        console.log(response);
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('logado', true)
      })
      .catch(function (error) {
        // manipula erros da requisição
        console.error(error);
      });
  };

  const EfetuarLogin = () => {

    ApiCliente.post('/loginCliente', {
      telefone: telefone,

    }).then(function (response) {
      const token = response.data.token;
      console.log(response);
      dispatch(setToken(token));
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('logado', true)
    })
      .catch(function (error) {
        console.log(error)
      });
  }


  const getClienteTelefone = () => {
    ApiCliente.post('clientes/telefone', {
      telefone: telefone
    }).then(function (response) {
      console.log(response.data);
      localStorage.setItem('cliente', JSON.stringify(response.data));
      localStorage.setItem('logado', true);
      onClose();
    }).catch(function (error) {
      console.log(error);
    })
  }


  const handleFormClose = () => {
    setFormOpen(false);
    setAddressFormOpen(false);
    onClose();
  };

  const handleCepChange = (e) => {
    setCep(e.target.value);
  };





  const handleBuscarEndereco = () => {
    setLoading(true); // Show loading indicator
    ApiCliente.post('/buscar-endereco', { cep }) // Envia o CEP para o backend
      .then((response) => {
        const { latitude, longitude } = response.data;
        setLatitude(latitude);
        setLongitude(longitude);
        setBairro(response.data.endereco.bairro)
        setRua(response.data.endereco.logradouro)
        setLoading(false);
        console.log(response.data)
      })
      .catch((error) => {
        console.error(error);
        alert('cep inválido')
        setLoading(false)
      });
  };


  const handleAddressFormOpen = () => {
    if (telefone.length === 15) {
      setAddressFormOpen(true);
    }

  };

  const handleAddressFormClose = () => {
    setAddressFormOpen(false);
    onClose();
  };



  return (
    <>
      <Modal open={open} onClose={handleFormClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '100%', lg: '600px' },
            minHeight: { xs: '100%', lg: '600px' },
            bgcolor: '#f5f5f5'
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: '0',
              zIndex: 1,
              boxShadow: 2,
              p: 1,
              bgcolor: '#fff'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  width: '30px',
                  height: '100%',
                  borderRadius: '50%',
                  bgcolor: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
                onClick={handleFormClose}
              >
                <ArrowDownward />
              </Box>
              <Typography variant="h6" sx={{ ml: 4 }}>
                Cadastro
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', p: 3, mt: 5, gap: 3 }}>
            <Typography color="#888" sx={{ fontSize: '24px' }}>
              Preencha o formulário de cadastro
            </Typography>
            <TextField
              label="Nome completo"
              placeholder="Nome completo"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <InputMask mask="(99) 99999-9999" value={telefone} onChange={(e) => setTelefone(e.target.value)}>
              {() => <TextField label='Telefone para contato' required />}
            </InputMask>
            <Button variant="contained" onClick={handleAddressFormOpen}>
              Cadastrar
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={isAddressFormOpen} onClose={handleAddressFormClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '100%', lg: '600px' },
            height: { xs: '100%', lg: '600px' },
            bgcolor: '#f5f5f5',
            overflow: 'auto'
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: '0',
              zIndex: 1,
              boxShadow: 2,
              p: 1,
              bgcolor: '#fff'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  width: '30px',
                  height: '100%',
                  borderRadius: '50%',
                  bgcolor: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
                onClick={handleAddressFormClose}
              >
                <ArrowDownward />
              </Box>
              <Typography variant="h6" sx={{ ml: 4 }}>
                Preencher Endereço
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', p: 3, gap: 3 }}
            component={'form'}
            onSubmit={() => {
              CadastrarCliente();

              handleAddressFormClose()
            }}
          >
            <Typography color="#888" sx={{ fontSize: '24px' }}>
              Preencha as informações do endereço
            </Typography>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress /> {/* Import CircularProgress from @mui/material */}
              </Box>
            ) : (
              <>

                <InputMask
                  mask="99999-999"
                  value={cep}
                  onChange={(e) => setCep(e.target.value)}
                >
                  {() => <TextField label="Código Postal (CEP)" required />}
                </InputMask>
                <Button variant="contained" onClick={handleBuscarEndereco}>
                  Buscar Endereço
                </Button>
                <TextField label="Bairro" placeholder="Bairro" value={bairro} onChange={(e) => setBairro(e.target.value)} disabled required />
                <TextField label="Rua" placeholder="Rua" value={rua} onChange={(e) => setRua(e.target.value)} disabled required />
                <TextField label="casa" placeholder='casa' value={numero} onChange={(e) => setNumero(e.target.value)} required />
                <TextField label="Complemento" placeholder="Complemento" value={complemento} onChange={(e) => setComplemento(e.target.value)} />

                <Button variant="contained" type='submit'>
                  Cadastrar
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Modal>

    </>
  );
};
