// localStorageUtils.js
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearToken } from '../redux/authActions';

export const useLocalStorageListener = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'token' && event.newValue === null) {
        dispatch(clearToken());
      }
    };

    const handleStorageClear = (event) => {
      if (event.key === null && event.newValue === null) {
        dispatch(clearToken());
      }
    };

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('storage', handleStorageClear);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('storage', handleStorageClear);
    };
  }, [dispatch]);
};
