import { Box, Button, Modal, TextField, Typography } from "@mui/material"
import { FormPagamento } from "./FormPagamento"
import { ArrowDownward } from '@mui/icons-material';
import { ApiCliente } from "../config/ApiCliente";
import { FormCadastroCliente } from "./FormCadastroCliente";
import InputMask from 'react-input-mask';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setToken } from "../redux/authActions";


export const FormLogin = ({ open, onClose}) => {
  const [telefone, setTelefone] = useState('');
  const [abrirFormCadastro, setAbrirFormCadastro] = useState(false);
  const [abrirFormPagamento, setAbrirFormPagamento] = useState(false);
  const dispatch = useDispatch();



  const getClienteTelefone =()=>{
    ApiCliente.post('clientes/telefone', {
      telefone: telefone
    }).then(function(response){
      console.log(response.data);      
      localStorage.setItem('cliente', JSON.stringify(response.data));
      localStorage.setItem('logado', true);
      EfetuarLogin()
      onClose();
    }).catch(function(error){
      console.log(error);
      setAbrirFormCadastro(true)
      onClose();
    })
  }

  const EfetuarLogin =()=>{
 
    ApiCliente.post('/loginCliente', {
      telefone:telefone,
      
      }).then(function (response) {
        const token = response.data.token; 
        console.log(response);
        dispatch(setToken(token));
        localStorage.setItem('token',response.data.token)
        localStorage.setItem('logado',true)
      })
      .catch(function (error) {
        console.log(error)
      });
    }

  const Continuar =() => {
    getClienteTelefone()
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '100%', lg: '600px' },
            minHeight: { xs: '100%', lg: '600px' },
            bgcolor: '#f5f5f5',
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: '0',
              zIndex: 1,
              boxShadow: 2,
              p: 1,
              bgcolor: '#fff',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  width: '30px',
                  height: '100%',
                  borderRadius: '50%',
                  bgcolor: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={onClose}
              >
                <ArrowDownward />
              </Box>
              <Typography variant="h6" sx={{ ml: 4 }}>
                Entrar ou Cadastrar
              </Typography>
            </Box>
          </Box>

          <Box  sx={{ display: 'flex', flexDirection: 'column', p: 3, mt: 5, gap: 6}}>
            <Typography color="#888" sx={{ fontSize: '24px' }}>
              Informe o seu número de celular para continuar
            </Typography>
            <InputMask mask="(99) 99999-9999" value={telefone} onChange={(e) => setTelefone(e.target.value)}>
               {()=> <TextField label='Telefone para contato' required />}
              </InputMask>
            <Button variant="contained" onClick={Continuar}>
              Continuar
            </Button>
          </Box>
        </Box>
      </Modal>

      {abrirFormPagamento && (
        <FormPagamento
          open={abrirFormPagamento}
          onClose={() => setAbrirFormPagamento(false)}
          
        />
      )}

      <FormCadastroCliente open={abrirFormCadastro} onClose={() => setAbrirFormCadastro(false)} />
    </>
  );
};
