import { useContext, useEffect, useRef, useState } from "react";
import { ApiCliente } from "../../config/ApiCliente";
import { Box, Button, CardMedia, Container, IconButton, Tab, Tabs, Typography, TextField } from "@mui/material";
import { ModalProduto } from "../../components/ModalProduto";
import { BotaoVerCarrinho } from "../../components/BotaoVerCarrinho";
import { CartContext } from "../../context/CartProvider";
import MenuBottom from "../../components/MenuBottom";
import { yellow } from "@mui/material/colors";
import SearchIcon from '@mui/icons-material/Search';
import { io } from 'socket.io-client';


export const HomeCliente = () => {
  const [categorias, setCategorias] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [produtoSelecionado, setProdutoSelecionado] = useState();
  const [openModalProduto, setOpenModalProduto] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const containerRef = useRef(null);
  const { carrinho } = useContext(CartContext);
  const cliente = JSON.parse(localStorage.getItem('cliente'));

  const getClienteTelefone =()=>{
    ApiCliente.post('clientes/telefone', {
      telefone: cliente ? cliente.data.telefone :0
    }).then(function(response){
      localStorage.setItem('cliente', JSON.stringify(response.data));
      localStorage.setItem('logado', true);
    
    }).catch(function(error){
      console.log(error);
      console.log('dados removido do localstorage')
      localStorage.clear()
    
    })
  }

  useEffect(() => {
    
    buscarCategorias();
    getClienteTelefone()

    // Configurar o Socket.io no cliente
    const socket = io('https://api.lanchedobaba.gdelivery.app.br');

    // Ouvir o evento de atualização de pedidos (não precisa fazer alterações aqui, caso você já esteja usando os dados de pedidos em outra parte do código)


    // Ouvir o evento de atualização de produtos
    socket.on('produtosAtualizados', (data) => {
      // Atualizar as categorias quando houver atualização de produtos
      buscarCategorias();
    });

    return () => {
      socket.disconnect(); // Desconectar o socket quando o componente for desmontado
    };
  }, []);

  const buscarCategorias = () => {
    ApiCliente.get("categorias/")
      .then(function (response) {
        setCategorias(response.data.data);
        console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const BuscarProduto = (id) => {
    ApiCliente.get('produto/' + id).then(function (response) {
      setProdutoSelecionado(response.data.data);
      console.log(response.data);
      setOpenModalProduto(true);
    }).catch(function (error) {
      console.log(error);
    });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    scrollToSection(newValue);
  };

  const scrollToSection = (index) => {
    const sectionId = categorias[index].id.toString();
    const section = document.getElementById(sectionId);

    if (section) {
      const headerHeight = 140; // Altura do cabeçalho fixo, ajuste esse valor conforme necessário.
      const sectionTop = section.offsetTop - headerHeight;
      window.scrollTo({ top: sectionTop, behavior: "smooth" });
    }
  };

  const handleScroll = () => {
    const containerTop = containerRef.current.offsetTop;
    const scrollPosition = window.pageYOffset;

    let activeIndex = 0;
    let minDistance = Infinity;

    categorias.forEach((categoria, index) => {
      const section = document.getElementById(categoria.id);
      if (section) {
        const sectionTop = section.offsetTop - containerTop;
        const distance = Math.abs(sectionTop - scrollPosition);
        if (distance < minDistance) {
          minDistance = distance;
          activeIndex = index;
        }
      }
    });

    setActiveTab(activeIndex);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [categorias]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const toggleSearch = () => {
    setShowSearch((prevShowSearch) => !prevShowSearch);
  };

  return (
    <>
      <Box sx={{ position: 'sticky', top: 0, left: 0, right: 0, zIndex: 1, bgcolor: yellow['500'], textAlign: 'center' }}>
        <Container maxWidth={"md"} ref={containerRef}>
          <Box sx={{ display: 'flex', p: 1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ height: '80px', width: '80px' }}>
              <img src="/logo.png" width={'100%'} height={'100%'} />
            </Box>

            <IconButton onClick={toggleSearch}>
              <SearchIcon />
            </IconButton>
          </Box>
          {showSearch && (
            <TextField
              placeholder="Pesquisar item..."
              variant="outlined"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              {categorias.map((categoria, index) => (
                <Tab key={categoria.id} value={index} label={categoria.nome} />
              ))}
            </Tabs>
          </Box>
        </Container>
      </Box>
      <Container maxWidth={"md"}>
        {categorias.map((categoria, index) => (
          <Box key={categoria.id} id={categoria.id}>
            <Box sx={{ border: "1px solid #eee", p: 2 }}>
              <Typography variant="h4">{categoria.nome}</Typography>
            </Box>
            {categoria.Produtos.filter((produto) =>
              produto.status === "ativo" && produto.titulo.toLowerCase().includes(searchQuery.toLowerCase())
            ).map((produto) => (
              <Box
                key={produto.id}
                component={'div'}
                onClick={() => BuscarProduto(produto.id)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 1,
                  border: "1px solid #eee",
                  cursor: 'pointer',
                  transition: "background-color 0.5s",
                  ":hover": { backgroundColor: 'lightgrey' }
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Typography sx={{ fontSize: '18px' }}>{produto.titulo}</Typography>
                  <Typography sx={{ color: '#888' }}>{produto.descricao}</Typography>
                  <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>R$ {produto.valor.toFixed(2)}</Typography>
                </Box>
                <CardMedia
                  sx={{ width: 100, height: 100, marginX: 5 }}
                  image={"https://api.lanchedobaba.gdelivery.app.br/files/" + produto.img}
                />
              </Box>
            ))}
          </Box>
        ))}
        {produtoSelecionado && (
          <ModalProduto openModal={openModalProduto} closeModal={() => setOpenModalProduto(false)} produto={produtoSelecionado} />
        )}
        {carrinho.length > 0 ? <BotaoVerCarrinho /> : null}
        <MenuBottom />
      </Container>
    </>
  );
};
