import React, { useContext, useState } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { FormaDePagamentoContext } from '../context/CartProvider';
import { ModalCarrinho } from './ModalCarrinho';

export const FormPagamento = ({ open, onClose }) => {

  const { formasPagamento,setFormaPagamentoSelecionada } = useContext(FormaDePagamentoContext);
  const[abreModalCarrinho,setAbreModalCarrinho]=useState(false)

  const handleFormaPagamentoSelecionada = (forma) => {
    const formaSelecionada = formasPagamento.find((item) => item.nome === forma);
    setFormaPagamentoSelecionada(formaSelecionada);
    onClose()
   
  };
  
  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '100%', lg: '600px' },
            minHeight: { xs: '100%', lg: '600px' },
            bgcolor: '#f5f5f5',
          }}
        >
          <Box
            sx={{
              position: 'sticky',
              top: '0',
              zIndex: 1,
              boxShadow: 2,
              p: 1,
              bgcolor: '#fff',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  width: '30px',
                  height: '100%',
                  borderRadius: '50%',
                  bgcolor: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={onClose}
              >
                <ArrowDownward />
              </Box>
              <Typography variant="h6" sx={{ ml: 4 }}>
                Formas de Pagamento
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', p: 2 }}>
            <Typography>Pague na entrega</Typography>
          </Box>
          <>
            {formasPagamento.map((formas, index) => ( 

              <Box
                key={index}
              
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  p: 1,
                  border: '1px solid #eee',
                  cursor: 'pointer',
                  transition: 'background-color 0.5s',
                  ':hover': { backgroundColor: 'lightgrey' },
                 
                }}
                onClick={() => handleFormaPagamentoSelecionada(formas.nome)}
              >
                <Typography>{formas.nome}</Typography>
                <IconButton>{formas.icone}</IconButton>
              </Box>
            ))}
            <ModalCarrinho open={abreModalCarrinho} onClose={() => setAbreModalCarrinho(false)} />

           
          </>
        </Box>
      </Modal>
    </>
  );
};
