import { Alert, Snackbar } from "@mui/material"


export const NotificaoSnack = ({openNotificao,closeNotificacao,children,requisicaoOk,requisicaoOf})=>{

    return(
        <Snackbar open={openNotificao} autoHideDuration={3000} onClose={closeNotificacao}>
      <Alert onClose={closeNotificacao} severity={requisicaoOk ? 'success' :'error'} sx={{ width: '100%' }}>
         {children}
        </Alert>
      </Snackbar>
    )
}