import React, { createContext, useEffect, useState } from 'react';
import PixIcon from '@mui/icons-material/Pix';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreditCardIcon from '@mui/icons-material/CreditCard';

export const CartContext = createContext();
export const FormaDePagamentoContext = createContext();
export const CartProvider = ({ children }) => {
  const [carrinho, setCarrinho] = useState([]);
  const [taxaEntrega, setTaxaEntrega] = useState(0);
  const [formaPagamentoSelecionada, setFormaPagamentoSelecionada] = useState(null);

  const formasPagamento = [
    {
      nome: 'Dinheiro',
      icone: <AttachMoneyIcon />,
    },
    {
      nome: 'Cartão de crédito',
      icone: <CreditCardIcon />,
    },
    {
      nome: 'Cartão de Débito',
      icone: <CreditCardIcon />,
    },
    {
      nome: 'Pix',
      icone: <PixIcon />,
    },
  ];

  useEffect(() => {
    console.log("Meu carrinho:", carrinho);
    if (carrinho.length === 0) {
      setFormaPagamentoSelecionada(null);
    }
  }, [carrinho]);

const adicionaProduto = (produto, complementosSelecionados, observacao) => {
  const produtoExistente = carrinho.find((item) => {
    const complementosIguais =
      item.complementos.length === complementosSelecionados.length &&
      item.complementos.every((complementoA) =>
        complementosSelecionados.some((complementoB) => complementoB.id === complementoA.id)
      );
    const observacaoIgual = item.observacao === observacao; // Verificar se a observação é igual

    return item.id === produto.id && complementosIguais && observacaoIgual;
  });

  if (produtoExistente) {
    const novoCarrinho = carrinho.map((item) => {
      if (item === produtoExistente) {
        return {
          ...item,
          qtd: item.qtd + 1,
          valorTotal: (item.qtd + 1) * item.valorTotal / item.qtd,
        };
      }
      return item;
    });
    setCarrinho(novoCarrinho);
  } else {
      const novoProduto = {
        id: produto.id,
        titulo: produto.titulo,
        descricao: produto.descricao,
        img: produto.img,
        valor: produto.valor,
        qtd: 1,
        observacao: observacao,
        complementos: complementosSelecionados.map((complemento) => ({
          id: complemento.id,
          titulo: complemento.titulo,
          valor: complemento.valor,
          qtd: 1,
        })),
        valorTotal: produto.valor + complementosSelecionados.reduce((acumulador, complemento) => acumulador + complemento.valor, 0),
      };
      setCarrinho((prevCarrinho) => [...prevCarrinho, novoProduto]);
    }
  };

  const removeProduto = (produto,observacao) => {
    const produtoExistente = carrinho.find((item) => {
      const complementosIguais =
        item.complementos.length === produto.complementos.length &&
        item.complementos.every((complementoA) =>
          produto.complementos.some((complementoB) => complementoB.id === complementoA.id)
        );
        const observacaoIgual = item.observacao === observacao; 
        return item.id === produto.id && complementosIguais && observacaoIgual;
    });

    if (produtoExistente) {
      const novoCarrinho = carrinho.map((item) => {
        if (item === produtoExistente) {
          if (item.qtd === 1) {
            return null; // Remover o produto do carrinho se a quantidade for igual a 1
          } else {
            return {
              ...item,
              qtd: item.qtd - 1,
              valorTotal: item.valorTotal - item.valorTotal / item.qtd,
            };
          }
        }
        return item;
      });

      const carrinhoAtualizado = novoCarrinho.filter((item) => item !== null);

      setCarrinho(carrinhoAtualizado);
    }
  };

  const atualizarTaxaEntrega = (novaTaxaEntrega) => {
    setTaxaEntrega(novaTaxaEntrega);
  };

  

  return (
    <CartContext.Provider value={{ adicionaProduto, removeProduto, carrinho,setCarrinho, atualizarTaxaEntrega }}>
      <FormaDePagamentoContext.Provider value={{formasPagamento,formaPagamentoSelecionada,setFormaPagamentoSelecionada}}>
      {children}
      </FormaDePagamentoContext.Provider>
    </CartContext.Provider>
  );
};
