import { Box, Button, Container, Modal, TextField, Typography } from "@mui/material";
import { ArrowDownward } from '@mui/icons-material';
import axios from "axios";
import { useState } from "react";
import { ApiCliente } from "../config/ApiCliente";
import { CircularProgress } from "@mui/material";
import InputMask from 'react-input-mask';
export const FormEditEndereco = ({ open, onClose }) => {
  const [cep, setCep] = useState('');
  const [bairro, setBairro] = useState('');
  const [rua, setRua] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const cliente = JSON.parse(localStorage.getItem('cliente'));
  const [isLoading, setIsLoading] = useState(false);
 
  const AtualizarEndereco = (event) => {
    
    event.preventDefault(); 
    ApiCliente.put('clientes/atualizar/' + cliente.data.id, {
      rua: rua,
      numero: numero,
      bairro: bairro,
      cep: cep,
      complemento: complemento,
      long: longitude,
      lat: latitude
    })
      .then(function (response) {
        console.log(response);
        updateLocalStorageWithNewData(response.data.data); // Atualiza o localStorage com os novos dados do cliente
     // Chama a função para atualizar outros dados no localStorage
     onClose()
     
      })
      .catch(function (error) {
        // manipula erros da requisição
        console.error(error);
      });
  };
  
  // Função para atualizar o localStorage com os novos dados do cliente
  const updateLocalStorageWithNewData = (newData) => {
    // Verifica se o objeto cliente já existe no localStorage
    const existingData = JSON.parse(localStorage.getItem('cliente'));
  
    // Se já existir, atualize a propriedade "data" do objeto cliente
    if (existingData) {
      existingData.data = { ...existingData.data, ...newData };
      localStorage.setItem('cliente', JSON.stringify(existingData));
    } else {
      // Se não existir, crie um novo objeto com a propriedade "data"
      const updatedCliente = { data: newData };
      localStorage.setItem('cliente', JSON.stringify(updatedCliente));
    }
  };
  

  

  const handleBuscarEndereco = () => {
    setIsLoading(true)
    ApiCliente.post('/buscar-endereco', { cep }) // Envia o CEP para o backend
      .then((response) => {
        const { latitude, longitude } = response.data;
        setIsLoading(false)
        setLatitude(latitude);
        setLongitude(longitude);
        setBairro(response.data.endereco.bairro)
        setRua(response.data.endereco.logradouro)
        setIsLoading(false)
        console.log(response.data)
      })
      .catch((error) => {
        console.error(error);
        alert('cép invalido')
        setIsLoading(false)
    
      });
  };




  return (
    <Modal open={open} onClose={onClose}>
      {isLoading? (
 <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
 <CircularProgress />
   </Container>
      ):(

     
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '100%', lg: '600px' },
          height: { xs: '100%', lg: '600px' },
          bgcolor: '#f5f5f5',
          overflow: 'auto'
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: '0',
            zIndex: 1,
            boxShadow: 2,
            p: 1,
            bgcolor: '#fff'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                width: '30px',
                height: '100%',
                borderRadius: '50%',
                bgcolor: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
              }}
              onClick={onClose}
            >
              <ArrowDownward />
            </Box>
            <Typography variant="h6" sx={{ ml: 4 }}>
              Preencher Endereço
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', p: 3, gap: 3 }}
         
          component={'form'}
          onSubmit={AtualizarEndereco}
        >
          <Typography color="#888" sx={{ fontSize: '24px' }}>
            Preencha as informações do endereço
          </Typography>
          <InputMask
      mask="99999-999"
      value={cep}
      onChange={(e) => setCep(e.target.value)}
    >
      {() => <TextField label="Código Postal (CEP)" required />}
    </InputMask>
        
          <Button variant="contained" onClick={handleBuscarEndereco}>
            Buscar Endereço
          </Button>
          <TextField label="Bairro"  placeholder="Bairro" value={bairro} onChange={(e) => setBairro(e.target.value)} disabled required />
          <TextField label="Rua" placeholder="Rua" value={rua} onChange={(e) => setRua(e.target.value)} disabled required />
          <TextField label="casa" placeholder='casa' value={numero} onChange={(e) => setNumero(e.target.value)} required />
          <TextField label="Complemento" placeholder="Complemento" value={complemento} onChange={(e) => setComplemento(e.target.value)} />

          <Button variant="contained" type="submit" >
            Atualizar Endereço
          </Button>
        </Box>
      </Box>
       )
      }
    </Modal>
  );
};
